$colors: (
  black: #303a52,
  purple: #574b90,
  lilac: #9e579d,
  pink: #fc85ae,
  blue: #43dde6,
  grey: #f0f0f0,
);

@mixin text-shadow-3d($px, $color) {
  transform: translate($px + px, -$px + px);
  text-shadow: multiple-text-shadow($px, $color);
}

@function color($color) {
  @return map-get($colors, $color);
}

@function multiple-text-shadow($n, $color) {
  $value: "-1px 1px 0 #{$color}";
  @for $i from 2 through $n {
    $value: "#{$value} , #{$i * -1}px #{$i * +1}px 0 "+darken($color, $i);
  }

  @return unquote($value);
}

html {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
}

body {
  background-color: color(grey);
  line-height: 1;
}

.main {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.top-panel {
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  height: 60px;
  pointer-events: none;

  .logo {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    margin: 0;
    color: color(lilac);
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: inherit;
    pointer-events: auto;

    &__text {
      user-select: none;
      transition: 0.5s ease;
    }

    &:hover .logo__text {
      @include text-shadow-3d(10, color(pink));
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;

    &__route {
      pointer-events: auto;
      padding: 20px;
      color: color(lilac);
      cursor: pointer;

      text-decoration: inherit;

      .nav__text {
        user-select: none;
        transition: 0.5s ease;
      }

      &_active .nav__text,
      &:hover .nav__text {
        @include text-shadow-3d(5, color(pink));
      }
    }
  }
}

.intro-panel {
  height: 100vh;
  width: 100vw;
  background: color(grey);
  // clip-path: polygon(0 0, 85% 0, 63% 100%, 0% 100%);
  position: relative;

  display: grid;
  // justify-content: center;
  // align-items: center;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);

  &::before {
    content: "Simeon Ivanov";
    font-size: 20em;
    font-weight: bold;

    position: absolute;
    top: 20%;
    left: -10%;
    word-wrap: none;
    white-space: nowrap;

    z-index: 0;

    color: darken($color: color(grey), $amount: 3);
  }

  .headings {
    grid-column: 3 / 7;
    grid-row: 4 / 7;

    z-index: 2;

    &__primary-title {
      color: color(purple);
      font-size: 6rem;
      letter-spacing: -0.1rem;
      text-transform: uppercase;
      &:hover .tongue {
        transform-origin: top right;
        transform: rotate(-20deg);
        transition: 0.5s;
      }
    }

    &__secondary-title {
      color: color(lilac);
      font-size: 3rem;
      font-weight: lighter;
      letter-spacing: -0.1rem;
      white-space: nowrap;
    }
  }
}
