$green: #006b65;
$purple: #574b90;
$lilac: #9e579d;

.name-character {
  position: relative;

  &:hover .top-lash {
    clip-path: polygon(0 0, 80% 0, 75% 100%, 0 100%);

    transition: 1s;
  }
  &:hover .bottom-lash {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 22% 100%);

    transition: 1s;
  }

  &__eye {
    position: absolute;
    z-index: -2;
    color: $green;

    line-height: 0px;
    font-size: 50px;
    transform: translate(-50%, -50%);

    &::before {
      content: "•";
      color: #000;
      position: absolute;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .top-lash {
    background: $purple;
    position: absolute;
    top: 50%;
    left: 10%;
    width: 40%;
    height: 50%;
    z-index: -1;
    transform: translate(0, -50%);

    border-radius: 100% 0 0 100%;
    clip-path: polygon(0 0, 50% 0, 32% 99%, 0 100%);

    animation-name: blink;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-delay: 4s;
    // transition: clip-path 1s;
    transition: all 0.5s ease;
  }

  .bottom-lash {
    background: $purple;
    position: absolute;
    top: 50%;
    right: 10%;
    width: 40%;
    height: 50%;
    z-index: -1;
    transform: translate(0, -50%);

    border-radius: 0 100% 100% 0;
    clip-path: polygon(59% 0, 100% 0, 100% 100%, 68% 100%);

    transition: clip-path 1s;
  }
}

@keyframes blink {
  0% {
    clip-path: polygon(0 0, 50% 0, 32% 99%, 0 100%);
  }

  95% {
    clip-path: polygon(0 0, 50% 0, 32% 99%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.tongue {
  position: absolute;
  z-index: -1;
  background-color: $purple;
  border-left: 3px solid $lilac;
  width: 12px;
  height: 50%;
  border-radius: 0 0 100% 10%;

  top: 22px;
  right: 0px;
  transform-origin: top right;
  transform: rotate(18deg);
  transition: 0.5s;
}
