.card-field {
  height: 100vh;
  width: 100vw;
  padding-top: 60px;

  &__content-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 5px;
  }
  &__content {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(calc(486px / 4), 1fr));
    justify-items: center;
    align-items: center;
    font-size: 4px;
  }
}
