$lilac: #574b90;

.not-found-title {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__number {
    font-size: 3em;
    font-weight: bold;
    color: $lilac;
  }

  &__img {
    max-height: 4em;
    margin: 0.5em;

    cursor: pointer;
    pointer-events: none;

    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
