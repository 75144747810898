$card-colors: (
  white: #f1f1f1,
  red: #ff5555,
  yellow: #ffaa00,
  green: #55aa55,
  blue: #5555fd,
  black: #000000,
  back-red: #ed1c24,
);

@function color($color) {
  @return map-get($card-colors, $color);
}

@mixin card-ellipse {
  width: 100%;
  height: 100%;
  clip-path: ellipse(40% 48% at 50% 50%);
  transform: rotate(30deg);
}

@mixin card-front-symbol {
  position: absolute;
  font-size: 5em;
  font-weight: bold;
  color: color(white);

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000000;

  text-shadow: 2px 2px 0px #000000;
}

.card {
  width: calc(486px / 4); // 486px
  height: calc(759px / 4); // 759
  z-index: 10;
  position: relative;
}
.front,
.back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;

  border-radius: calc(36px / 4);
  padding: calc(36px / 4);

  background: color(white);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.front {
  transform: perspective(600px) rotateY(0deg);
}
.back {
  transform: perspective(600px) rotateY(180deg);
}

.front-content,
.back-content {
  color: color(white);
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: calc(36px / 4);
}

.front-content {
  position: relative;
  font-style: italic;

  .front-ellipse {
    @include card-ellipse();
    background: color(white);
  }
  .top-sign {
    @include card-front-symbol();
    top: 5%;
    left: 5%;
  }
  .center-sign {
    @include card-front-symbol();
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 20em;

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;

    text-shadow: 5px 5px 0px #000000;
  }
  .bottom-sign {
    @include card-front-symbol();
    transform: rotate(180deg);
    bottom: 5%;
    right: 5%;
  }
}
.back-content {
  background: color(black);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-content-logo {
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
}

.card:hover > .front {
  transform: perspective(600px) rotateY(-180deg);
}
.card:hover > .back {
  transform: perspective(600px) rotateY(0deg);
}
